
.main-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 0 20px 20px 20px;
    .main-wrapper-table{
        display: flex;
        flex-direction: column;
        height: 100%;
        .main-wrapper-scrollbar{
            height: 100%;
            ::v-deep .el-scrollbar__wrap {
                overflow-x: hidden;
            }
        }
    }
    .goods-text-table {
        .answer {
            display: flex;
            align-items: center;
            img {
                width: 80px;
                height: 80px;
                margin-right: 15px;
            }
        }
        .a-link{
            height: 80px;
            display: flex;
            align-items: center;
            .text {
                flex: 1;
                width: 1%;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
            }
        }
    }
    .dialog-footer{
        text-align: center;
        margin-top: 20px;
    }

    /*.order-main{*/
    /*    display: flex;*/
    /*    flex-direction: column;*/
    /*    height: 100%;*/
    /*}*/
}
